import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { AuthContext } from "./AuthProvider";

const Layout = ({ children }) => {
  const { isAuthenticated, handleSignOut } = useContext(AuthContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [wasAboveThreshold, setWasAboveThreshold] = useState(window.innerWidth >= 768);
  const [isManuallyClosed, setIsManuallyClosed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const isNowAboveThreshold = window.innerWidth >= 768;

      if (isNowAboveThreshold && !wasAboveThreshold && !isManuallyClosed) {
        setIsSidebarOpen(true);
      } else if (!isNowAboveThreshold && wasAboveThreshold) {
        setIsSidebarOpen(false);
      }

      setWasAboveThreshold(isNowAboveThreshold);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [wasAboveThreshold, isManuallyClosed]);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => {
      const newState = !prev;
      if (!newState) {
        setIsManuallyClosed(true);
      } else {
        setIsManuallyClosed(false);
      }
      return newState;
    });
  };

  // Add login page to the list of paths where header should be hidden
  const hideHeaderForPaths = ["/designer", "/login", "/signup", "/auth/activate", "/auth/request-reset", "/auth/reset"];
  const shouldHideHeader = hideHeaderForPaths.includes(location.pathname);

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Sidebar - Increased z-index for mobile */}
      {isAuthenticated && (
        <div className="md:relative fixed z-30">
          <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        </div>
      )}

      {/* Main Content Area - Lower z-index */}
      <div
        className={`flex flex-grow flex-col transition-all duration-300 ease-in-out ${
          isAuthenticated && isSidebarOpen ? "md:ml-64" : "ml-0"
        }`}
      >
        {/* Scrollable Content */}
        <div className="flex flex-col h-full overflow-y-auto">
          {/* Header */}
          {!shouldHideHeader && (
            <div className="sticky top-0 z-20">
              <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
            </div>
          )}

          {/* Child Content */}
          <div className="flex-grow">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
