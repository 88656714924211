import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profile, setProfile] = useState(null); // Global profile state
  const [isInitialized, setIsInitialized] = useState(false); // Track initialization
  const navigate = useNavigate();
  
  // Function to read cookies by name
  const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };
  
  // Function to set a cookie
  const setCookie = (name, value, days = 7) => {
    const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/; SameSite=Strict`;
  };
  
  // Function to delete a cookie
  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Strict`;
  };

  const handleSignOut = () => {
    setIsAuthenticated(false);
    setProfile(null); // Clear profile state
    
    // Clear token from all storage mechanisms
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    localStorage.removeItem("email");
    deleteCookie("auth_token");
    
    window.location.href = "/"; // Redirect to the homepage or login page
  };

  const checkTokenValidity = async () => {
    // First check for token in a cookie (most resilient to hard refresh)
    let token = getCookie("auth_token");
    
    // If no cookie, try localStorage
    if (!token) {
      token = localStorage.getItem("token");
    }
    
    // If still no token, try sessionStorage
    if (!token) {
      token = sessionStorage.getItem("token");
    }
    
    // If no token found anywhere, user is not authenticated
    if (!token) {
      setIsAuthenticated(false);
      return;
    }
    
    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
      const currentTime = Date.now() / 1000; // Current time in seconds

      if (decodedToken.exp < currentTime) {
        // Token expired, clear all storage
        setIsAuthenticated(false);
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        localStorage.removeItem("email");
        deleteCookie("auth_token");
        navigate("/login"); // Redirect to login page
      } else {
        // Valid token, ensure it's stored in all mechanisms for redundancy
        setIsAuthenticated(true);
        localStorage.setItem("token", token);
        sessionStorage.setItem("token", token);
        setCookie("auth_token", token);
        await fetchProfile(); // Fetch the user's profile on valid token and await it
      }
    } catch (error) {
      console.error("Token validation error:", error);
      setIsAuthenticated(false);
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      localStorage.removeItem("email");
      deleteCookie("auth_token");
      navigate("/login");
    }
  };

  const fetchProfile = async () => {
    try {
      // Try cookie first, then localStorage, then sessionStorage
      let token = getCookie("auth_token");
      
      if (!token) {
        token = localStorage.getItem("token");
      }
      
      if (!token) {
        token = sessionStorage.getItem("token");
      }
      
      // If no token found anywhere, can't fetch profile
      if (!token) {
        return;
      }

      const response = await fetch("/api/users/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch profile.");
      }

      const data = await response.json();
      setProfile(data); // Update profile state
      
      // Ensure token is stored in all mechanisms for redundancy
      localStorage.setItem("token", token);
      sessionStorage.setItem("token", token);
      setCookie("auth_token", token);
    } catch (err) {
      console.error("Error fetching user profile:", err);
    }
  };

  // Check for token in URL parameters (for OAuth callbacks)
  const checkUrlForToken = async () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    
    if (token) {
      // Store the token in all storage mechanisms for maximum resilience
      localStorage.setItem('token', token);
      sessionStorage.setItem('token', token);
      setCookie('auth_token', token);
      
      // Clean up the URL (remove the token parameter)
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
      
      // Set auth state
      setIsAuthenticated(true);
      await fetchProfile(); // Wait for profile to be fetched
      return true;
    }
    return false;
  };

  // Run authentication initialization on mount
  useEffect(() => {
    const initAuth = async () => {
      console.log("Initializing auth...");
      
      // First check for token in URL (highest priority)
      const foundTokenInUrl = await checkUrlForToken();
      
      // If no token in URL, check stored tokens
      if (!foundTokenInUrl) {
        console.log("Checking stored tokens...");
        
        // Check for token in cookie directly (most resilient to hard refresh)
        const cookieToken = getCookie("auth_token");
        console.log("Cookie token:", cookieToken ? "Found" : "Not found");
        
        if (cookieToken) {
          console.log("Found token in cookie, restoring auth state");
          // If cookie has token, use it to restore auth state
          localStorage.setItem('token', cookieToken);
          sessionStorage.setItem('token', cookieToken);
          setIsAuthenticated(true);
          await fetchProfile();
        } else {
          // If no cookie token, check other stores
          await checkTokenValidity();
        }
      }
      
      // Mark initialization as complete
      setIsInitialized(true);
      console.log("Auth initialization complete, authenticated:", isAuthenticated);
    };
    
    initAuth();
  }, []);

  // Debug useEffect for isAuthenticated changes
  useEffect(() => {
    console.log("Authentication state changed:", isAuthenticated);
  }, [isAuthenticated]);
  
  // Expose profile state and a method to update it
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        profile, // Expose the profile state
        setProfile, // Expose a method to update the profile
        handleSignOut,
        isInitialized, // Expose initialization state
      }}
    >
      {isInitialized ? (
        children
      ) : (
        <div className="flex items-center justify-center h-screen bg-white">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}
    </AuthContext.Provider>
  );
};
