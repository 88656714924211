import React from 'react';

const UserAvatar = ({ avatarUrl, userName }) => {
  // Use a default Gravatar URL if no avatarUrl is provided
  const defaultAvatarUrl = `https://www.gravatar.com/avatar/?d=mp`; // 'mp' gives the mystery person icon

  return (
    <div className="flex items-center justify-center overflow-hidden rounded-full">
      <img
        src={avatarUrl || defaultAvatarUrl}  // Fallback to default if no avatarUrl is passed
        alt={`${userName}'s avatar`}
        className="w-10 h-10 rounded-full object-cover"
      />
    </div>
  );
};

UserAvatar.defaultProps = {
  userName: 'Guest',
};

export default UserAvatar;
