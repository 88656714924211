import React, { useContext, useEffect, useRef } from "react";
import UserAvatar from "./UserAvatar";
import UserMenu from "./UserMenu";
import { Link } from "react-router-dom";
import { AuthContext } from "../components/AuthProvider";

const Header = ({ isSidebarOpen, toggleSidebar }) => {
  const { isAuthenticated, profile, handleSignOut } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const menuRef = useRef(null); // Ref to track the menu

  const handleAvatarClick = () => {
    setMenuOpen((prev) => !prev);
  };

  // Close the menu if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="z-[50] bg-gray-50">
      <div className="flex h-14 items-center justify-between">
        {/* Menu Toggle and Logo */}
        <div className="flex items-center gap-2 pl-2">
          {!isSidebarOpen && isAuthenticated && (
            <button
              onClick={toggleSidebar}
              className="p-2 hover:bg-gray-100 rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          )}
        </div>

        {/* User Actions */}
        <div className="flex items-center gap-4">
          {isAuthenticated ? (
            <div className="relative flex items-center pr-2" ref={menuRef}>
              <button
                onClick={handleAvatarClick}
                className="focus:outline-none flex items-center justify-center"
              >
                <UserAvatar
                  avatarUrl={
                    profile?.avatar ||
                    "https://via.placeholder.com/150?text=Avatar" // Placeholder avatar
                  }
                  userName={profile?.email || "User"}
                />
              </button>
              {menuOpen && (
                <UserMenu userEmail={profile?.email} onSignOut={handleSignOut} />
              )}
            </div>
          ) : (
            <div className="pr-2">
              <Link
                to="/login"
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-full shadow-md hover:bg-gray-300 focus:ring-2 focus:ring-gray-400 focus:outline-none transition"
              >
                Login
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
