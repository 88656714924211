import React, { useState, useEffect } from 'react';

const HealthCheck = () => {
  const [health, setHealth] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkHealth = async () => {
      try {
        setLoading(true);
        const response = await fetch('/api/health');
        if (!response.ok) {
          throw new Error(`Health check failed with status: ${response.status}`);
        }
        const data = await response.json();
        setHealth(data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setHealth(null);
      } finally {
        setLoading(false);
      }
    };

    checkHealth();
    // Poll every 30 seconds
    const interval = setInterval(checkHealth, 30000);
    return () => clearInterval(interval);
  }, []);

  if (loading && !health) {
    return <div>Checking API health...</div>;
  }

  if (error) {
    return <div className="text-red-600">API Health Error: {error}</div>;
  }

  return (
    <div className="flex items-center">
      <div 
        className={`h-3 w-3 rounded-full mr-2 ${health?.status === 'ok' ? 'bg-green-500' : 'bg-red-500'}`} 
      />
      <span className="text-sm">
        {health?.status === 'ok' ? 'API Healthy' : 'API Unhealthy'}
      </span>
    </div>
  );
};

export default HealthCheck;