// App.js
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import { AuthProvider } from './components/AuthProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoadingSpinner from './components/LoadingSpinner';
import { DevTools, DevToolsScanner } from './devtools';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Lazy load components
const Home = lazy(() => import('./pages/Home'));
const Heatmap = lazy(() => import('./pages/Heatmap'));
const HeatmapV2 = lazy(() => import('./pages/HeatmapV2'));
const ReceiptUploader = lazy(() => import('./pages/ReceiptUploader'));
const Payment = lazy(() => import('./pages/Payment'));
const LeadsPage = lazy(() => import('./pages/Leads'));
const Todos = lazy(() => import('./pages/Todos'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const SavedProperties = lazy(() => import('./pages/SavedProperties'));
const Signup = lazy(() => import('./pages/Signup'));
const Login = lazy(() => import('./pages/Login'));
const ActivateAccount = lazy(() => import('./pages/ActivateAccount'));
const RequestReset = lazy(() => import('./pages/RequestReset'));
const Reset = lazy(() => import('./pages/Reset'));
const Profile = lazy(() => import('./pages/Profile'));
const Messages = lazy(() => import('./pages/Messages'));
const ArmorForge = lazy(() => import('./pages/ArmorForge'));
const GarmentCustomization = lazy(() => import('./components/GarmentCustomization'));
const UserManagement = lazy(() => import('./pages/UserManagement'));
const GithubIntegration = lazy(() => import('./pages/bugscout/GithubIntegration'));
const GitlabIntegration = lazy(() => import('./pages/bugscout/GitlabIntegration'));
const Issues = lazy(() => import('./pages/bugscout/Issues'));
const IssueDetail = lazy(() => import('./pages/bugscout/IssueDetail'));
const BugScoutConfig = lazy(() => import('./pages/bugscout/BugScoutConfig'));
const BugScoutLanding = lazy(() => import('./pages/bugscout/BugScoutLanding'));
const ChatRoom = lazy(() => import('./pages/ChatRoom'));
const TableLayoutGenerator = lazy(() => import('./pages/table_designer/TableLayoutGenerator'));
const KnowledgeGraphSearch = lazy(() => import('./pages/knowledge_graph/KnowledgeGraphSearch'));
const IndustryAnalysisV4 = lazy(() => import('./pages/industry_analysis/IndustryAnalysis'));
const ExpensesTracker = lazy(() => import('./pages/expenses/ExpensesTracker'));
const FinancesTracker = lazy(() => import('./pages/expenses/FinancesTracker'));
const AccountsManager = lazy(() => import('./pages/expenses/AccountsManager'));
const AuthCallback = lazy(() => import('./pages/auth/callback.tsx'));

// Define route paths as constants
const ROUTES = {
  HOME: '/',
  MAP: '/map',
  MAP_V2: '/map2',
  RECEIPTS: '/receipts',
  PAYMENT: '/payment',
  LEADS: '/leads',
  TODOS: '/todos',
  DASHBOARD: '/dashboard',
  PROPERTIES: '/properties',
  SIGNUP: '/signup',
  LOGIN: '/login',
  ACTIVATE_ACCOUNT: '/auth/activate',
  REQUEST_RESET: '/auth/request-reset',
  RESET: '/auth/reset',
  AUTH_CALLBACK: '/auth/callback',
  PROFILE: '/account/profile',
  MESSAGES: '/messages',
  ARMOR_FORGE: '/pgi',
  GARMENT_CUSTOMIZATION: '/designer',
  USER_MANAGEMENT: '/system/user-managment',
  GITHUB_INTEGRATION: '/integrations/github',
  GITLAB_INTEGRATION: '/integrations/gitlab',
  BUGSCOUT_ISSUES: '/bugscout/issues',
  BUGSCOUT_ISSUE_DETAIL: '/bugscout/issues/:id',
  BUGSCOUT_CONFIG: '/bugscout/config',
  BUGSCOUT_LANDING: '/bugscout/landing',
  CHAT: '/chat',
  TABLE_DESIGNER: '/table-designer',
  KNOWLEDGE_GRAPH: '/knowledge-graph',
  INDUSTRY_ANALYSIS: '/industry-analysis',
  EXPENSES_TRACKER: '/finances/expenses',
  FINANCES_TRACKER: '/finances',
  ACCOUNTS_MANAGER: '/finances/accounts',
};

const App = () => {
  return (
    <Router>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <AuthProvider>
          <Layout>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route index path={ROUTES.HOME} element={<Home />} />
                <Route path={ROUTES.MAP} element={<Heatmap />} />
                <Route path={ROUTES.MAP_V2} element={<HeatmapV2 />} />
                <Route path={ROUTES.RECEIPTS} element={<ReceiptUploader />} />
                <Route path={ROUTES.PAYMENT} element={<Payment />} />
                <Route path={ROUTES.LEADS} element={<LeadsPage />} />
                <Route path={ROUTES.TODOS} element={<Todos />} />
                <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
                <Route path={ROUTES.PROPERTIES} element={<SavedProperties />} />
                <Route path={ROUTES.SIGNUP} element={<Signup />} />
                <Route path={ROUTES.LOGIN} element={<Login />} />
                <Route path={ROUTES.ACTIVATE_ACCOUNT} element={<ActivateAccount />} />
                <Route path={ROUTES.REQUEST_RESET} element={<RequestReset />} />
                <Route path={ROUTES.RESET} element={<Reset />} />
                <Route path={ROUTES.AUTH_CALLBACK} element={<AuthCallback />} />
                <Route path={ROUTES.PROFILE} element={<Profile />} />
                <Route path={ROUTES.MESSAGES} element={<Messages />} />
                <Route path={ROUTES.ARMOR_FORGE} element={<ArmorForge />} />
                <Route path={ROUTES.GARMENT_CUSTOMIZATION} element={<GarmentCustomization />} />
                <Route path={ROUTES.USER_MANAGEMENT} element={<UserManagement />} />
                <Route path={ROUTES.GITHUB_INTEGRATION} element={<GithubIntegration />} />
                <Route path={ROUTES.GITLAB_INTEGRATION} element={<GitlabIntegration />} />
                <Route path={ROUTES.BUGSCOUT_ISSUES} element={<Issues />} />
                <Route path={ROUTES.BUGSCOUT_ISSUE_DETAIL} element={<IssueDetail />} />
                <Route path={ROUTES.BUGSCOUT_CONFIG} element={<BugScoutConfig />} />
                <Route path={ROUTES.BUGSCOUT_LANDING} element={<BugScoutLanding />} />
                <Route path={ROUTES.CHAT} element={<ChatRoom />} />
                <Route path={ROUTES.TABLE_DESIGNER} element={<TableLayoutGenerator />} />
                <Route path={ROUTES.KNOWLEDGE_GRAPH} element={<KnowledgeGraphSearch />} />
                <Route path={ROUTES.INDUSTRY_ANALYSIS} element={<IndustryAnalysisV4 />} />
                <Route path={ROUTES.EXPENSES_TRACKER} element={<ExpensesTracker />} />
                <Route path={ROUTES.FINANCES_TRACKER} element={<FinancesTracker />} />
                <Route path={ROUTES.ACCOUNTS_MANAGER} element={<AccountsManager />} />
              </Routes>
            </Suspense>
            {process.env.NODE_ENV === 'development' && (
              <>
                <DevTools />
                <DevToolsScanner />
              </>
            )}
          </Layout>
        </AuthProvider>
      </GoogleOAuthProvider>
    </Router>
  );
};

export default App;
