import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import SidebarLink from "./SidebarLink";
import SidebarSection from "./SidebarSection";
import HealthCheck from "./HealthCheck";

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <aside
      className={`fixed inset-y-0 left-0 z-20 w-64 bg-white shadow-lg h-screen transition-transform duration-300 ease-in-out flex flex-col justify-between ${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      {/* Top Section with Close and Search */}
      <div className="flex items-center justify-between p-1">
        <button
          onClick={toggleSidebar}
          className="p-2 hover:bg-gray-100 rounded-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 text-gray-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
      </div>

      {/* Sidebar Content */}
      <nav className="flex-1 overflow-y-auto flex flex-col gap-2 p-6">
        {/* Static Links */}
        <SidebarLink to="/" label="Home" />

        {isAuthenticated && (
          <>
            {/* CRM Section */}
            {/* <SidebarSection label="CRM">
              <SidebarLink to="/dashboard" label="Dashboard" />
              <SidebarLink to="/leads" label="Leads" />
              <SidebarLink to="/todos" label="Lead Pipeline" />
              <SidebarLink to="/map2" label="Map" />
              <SidebarLink to="/properties" label="Saved Properties" />
            </SidebarSection> */}

            {/* Chat Section */}
            {/* <SidebarSection label="Chat">
              <SidebarLink to="/chat" label="Chat Room" />
            </SidebarSection> */}

            {/* BugScout Section */}
            <SidebarSection label="BugScout">
              <SidebarLink to="/bugscout/landing" label="Landing" />
              <SidebarLink to="/bugscout/issues" label="Issues" />
              <SidebarLink to="/bugscout/config" label="Configuration" />
              <SidebarSection label="Integrations">
                <SidebarLink to="/integrations/github" label="GitHub" />
                <SidebarLink to="/integrations/gitlab" label="GitLab" />
              </SidebarSection>
            </SidebarSection>

            {/* Finances Section */}
            <SidebarSection label="Finances">
              <SidebarLink to="/finances" label="Cashflow Dashboard" />
              <SidebarLink to="/finances/expenses" label="Expense Tracker" />
              <SidebarLink to="/finances/accounts" label="Bank Accounts" />
            </SidebarSection>

            {/* <SidebarSection label="Sandbox">
              <SidebarLink to="/receipts" label="Receipts" />
              <SidebarLink to="/payment" label="Payment" />
              <SidebarLink to="/pgi" label="PGI" />
              <SidebarLink to="/designer" label="Designer" />
              <SidebarLink to="/table-designer" label="Table Designer" />
              <SidebarLink to="/knowledge-graph" label="Knowledge Graph" />
              <SidebarLink to="/industry-analysis" label="Industry Analysis" />
            </SidebarSection> */}

            <SidebarSection label="System">
              <SidebarLink to="/system/user-managment" label="Users" />
              <div className="ml-4 mt-2 mb-2">
                <HealthCheck />
              </div>
            </SidebarSection>
          </>
        )}
      </nav>

    </aside>
  );
};

export default Sidebar;
