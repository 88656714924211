import React from "react";
import { Link, useLocation } from "react-router-dom";

const SidebarLink = ({ to, label }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`flex items-center gap-2 ${
        isActive ? "p-2 bg-gray-200 font-bold" : "p-2 text-gray-600"
      } rounded-lg text-sm hover:bg-gray-100`}
    >
      <span className="font-medium">{label}</span>
    </Link>
  );
};

export default SidebarLink;
