import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className="absolute inset-0 min-h-[400px] flex items-center justify-center bg-white/90 z-50">
      <div className="flex flex-col items-center gap-4">
        <div className="relative">
          <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
        <div className="text-blue-500 text-lg font-medium tracking-wide animate-pulse">
          Loading...
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner; 