import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const SidebarSection = ({ label, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const hasActiveChild = (children) => {
    return React.Children.toArray(children).some((child) => {
      if (child.props.to && location.pathname.startsWith(child.props.to)) {
        return true;
      }

      // Recursively check for nested SidebarSections
      if (child.type === SidebarSection) {
        return hasActiveChild(child.props.children);
      }

      return false;
    });
  };

  useEffect(() => {
    if (hasActiveChild(children)) {
      setIsOpen(true); // Keep the section open if a child is active
    }
  }, [children, location]);

  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full p-2 text-gray-600 hover:text-gray-800 rounded-lg focus:outline-none focus:ring-0 text-sm hover:bg-gray-100"
      >
        <span className="font-medium">{label}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`w-4 h-4 transform transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {isOpen && <div className="ml-4 mt-2 flex flex-col gap-2">{children}</div>}
    </div>
  );
};

export default SidebarSection;
