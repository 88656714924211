import React from 'react';
import withComponentInfo from './ComponentDecorator';

/**
 * Example component showing how to use the ComponentDecorator
 * This will add data-* attributes to the component DOM element
 * for easier identification by the DevTools
 */
const DevToolsExample = () => {
  return (
    <div className="p-4 m-4 bg-blue-100 rounded-lg">
      <h3 className="text-lg font-bold mb-2">DevTools Example Component</h3>
      <p className="mb-2">
        This component demonstrates the use of the <code>withComponentInfo</code> HOC.
      </p>
      <p>
        Hold Ctrl (or Cmd on Mac) and click on this component to open its source file!
      </p>
    </div>
  );
};

// Example of how to use the withComponentInfo HOC - only the component name is needed
export default withComponentInfo(DevToolsExample, {
  name: 'DevToolsExample',
  lineNumber: 9 // Line where the component is defined
});