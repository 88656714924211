import React from 'react';

/**
 * HOC to decorate components with debugging information
 * This is used by the DevTools to identify components by name and source location
 */
const withComponentInfo = (WrappedComponent, options = {}) => {
  // Skip in production
  if (process.env.NODE_ENV !== 'development') {
    return WrappedComponent;
  }
  
  // Get component name
  const componentName = options.name || 
                        WrappedComponent.displayName || 
                        WrappedComponent.name || 
                        'UnknownComponent';
  
  // Get file path - let the server find the exact path based on component name
  const filePath = options.filePath || componentName;
  
  // Get line number
  const lineNumber = options.lineNumber || 1;
  
  // Create wrapper component
  const ComponentWithInfo = (props) => {
    // Data attributes for debugging
    const debugAttributes = {
      'data-component-name': componentName,
      'data-source-file': filePath,
      'data-source-line': lineNumber
    };
    
    // Return wrapped component with debug info
    return (
      <div {...debugAttributes} className="dev-component-wrapper">
        <WrappedComponent {...props} />
      </div>
    );
  };
  
  // Set display name for debugging
  ComponentWithInfo.displayName = `withComponentInfo(${componentName})`;
  
  return ComponentWithInfo;
};

export default withComponentInfo;